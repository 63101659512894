import { AsyncPipe, NgComponentOutlet, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  NgZone,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
  inject,
  signal
} from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { CompleteProfileDialogService } from '@ih/complete-profile-dialog';
import { ContentBodyComponent } from '@ih/content';
import { CommandPaletteComponent } from '@ih/debug';
import { InterceptHrefDirective } from '@ih/directives';
import { StorageKeys } from '@ih/enums';
import { AppConfig } from '@ih/interfaces';
import { type OnboardingPanelService, OnboardingService } from '@ih/onboarding';
import {
  AuthService,
  CheckForUpdateService,
  ConfigService,
  EmojiService,
  FirebaseService,
  IdleService,
  LayoutService,
  LazyInject,
  LazySnackBarService,
  RightSidenavService,
  StorageService
} from '@ih/services';
import { isPushApiSupported } from '@ih/utilities';
import { Observable, Subject, from } from 'rxjs';
import { filter, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { AddToHomeScreenService } from './add-to-home-screen/add-to-home-screen.service';
import { TopNavComponent } from './top-nav/top-nav.component';

@Component({
  selector: 'ih-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    AsyncPipe,
    NgComponentOutlet,
    NgIf,
    RouterOutlet,

    MatProgressBarModule,
    MatSidenavModule,

    CommandPaletteComponent,
    ContentBodyComponent,
    InterceptHrefDirective,

    TopNavComponent
  ]
})
export class AppComponent implements OnDestroy {
  @HostBinding('class.ih-root') hostClass = true;

  @ViewChild('sideNav') sideNav: MatSidenav;

  @ViewChild('rightSidenav') rightSidenav: MatSidenav;

  @ViewChild('devConsole', { read: ViewContainerRef }) devConsole: ViewContainerRef;

  @ViewChild('commandPalette') commandPalette: CommandPaletteComponent;

  private router = inject(Router);
  private config = inject(ConfigService<AppConfig>) as ConfigService<AppConfig>;
  private layout = inject(LayoutService);
  private lazyInject = inject(LazyInject);
  private idle = inject(IdleService);
  private auth = inject(AuthService);
  private firebase = inject(FirebaseService);
  private storage = inject(StorageService);
  private snackbar = inject(LazySnackBarService);
  private emoji = inject(EmojiService);
  private completeProfile = inject(CompleteProfileDialogService);
  private rightSidenavService = inject(RightSidenavService);
  private checkForUpdate = inject(CheckForUpdateService);
  private ngZone = inject(NgZone);
  private onboarding = inject(OnboardingService);

  config$ = this.config.config$;
  isAuthenticated$ = this.auth.isAuthenticated$;
  shouldBeOver$: Observable<boolean>;

  // defer loading the sideNav until the app is loaded
  leftDrawerComponent$ = this.idle.requestIdle$(5, 3000).pipe(
    switchMap(() => from(import('./side-nav/side-nav.component'))),
    map((m) => m.SideNavComponent)
  );

  rightDrawerComponent$ = this.rightSidenavService.componentType$.pipe(tap((cmp) => console.log(cmp)));

  loadingRoute = signal(false);

  private readonly destroy$ = new Subject<void>();
  private hasShownCompleteProfile$ = new Subject<void>();

  constructor() {
    this.idle.requestIdle(
      () => {
        this.checkForUpdate.init();

        this.lazyInject
          .get<AddToHomeScreenService>(() =>
            import('./add-to-home-screen/add-to-home-screen.service').then((m) => m.AddToHomeScreenService)
          )
          .then((a2hs) => {
            this.ngZone.run(() => {
              a2hs.showInstall$.next(window._showInstall);
            });
          });

        // Create a PerformanceObserver to observe the Largest Contentful Paint (LCP) entry
        const observer = new PerformanceObserver((list) => {
          const entries = list.getEntries();
          const lcpEntry = entries.find((entry) => entry.entryType === 'largest-contentful-paint');
          // Only send the beacon if the LCP element is an image
          // and the url is /home
          if (
            lcpEntry &&
            lcpEntry.element &&
            lcpEntry.element.tagName.toLowerCase() === 'img' &&
            location.pathname === '/home'
          ) {
            const src = lcpEntry.element.getAttribute('src');
            const sizes = lcpEntry.element.getAttribute('sizes');
            const body = new Blob([JSON.stringify({ src, sizes })], { type: 'application/json' });

            // Check if the beacon has already been sent
            if (!lcpEntry.element.hasAttribute('data-beacon-sent')) {
              navigator.sendBeacon('/api/campaign/lcp', body);
              // Set the flag to indicate that the beacon has been sent
              lcpEntry.element.setAttribute('data-beacon-sent', 'true');
            }
          }
        });

        // Observe the LCP entry
        observer.observe({ type: 'largest-contentful-paint', buffered: true });

        // cancel the observer after 10 seconds
        setTimeout(() => observer.disconnect(), 10000);
      },
      5,
      10000
    );

    performance.mark('appComponent.constructor');
    this.shouldBeOver$ = this.layout.shouldBeOver$;

    // listen to navigation start events to decide if we need to redirect
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        withLatestFrom(this.config.config$),
        takeUntil(this.destroy$)
      )
      .subscribe(([event, appConfig]) => {
        const navigationStartEvent = event as NavigationStart;

        this.loadingRoute.set(true);

        // check if there are any redirects that match the current url
        const redirect = appConfig.redirects?.find((r) => r.originalUrl === navigationStartEvent.url);

        // if there is a redirect then we need to navigate to the new url
        if (redirect) {
          // check if the redirect is local by checking if it starts with /
          if (redirect.redirectTo.startsWith('/')) {
            // if it is local then we need to navigate to the new url
            this.router.navigateByUrl(redirect.redirectTo);
          } else {
            window.location.href = redirect.redirectTo;
          }
        }
      });

    // close the sideNav when the location changes if we're small enough for the sideNav to not be locked or the sideNav is not locked
    this.router.events
      .pipe(
        withLatestFrom(this.shouldBeOver$, this.config.config$),
        // filter so we only close when the sideNav is closable
        filter(([routerEvent]) => routerEvent instanceof NavigationEnd),
        takeUntil(this.destroy$)
      )
      .subscribe(([, shouldBeOver, appConfig]) => {
        this.loadingRoute.set(false);
        // Wait for routing to finish before removing the loader
        // This prevents a blank page from showing while the component for the route is loading on first load
        const loader = document.getElementById('loader');
        if (loader) {
          loader.remove();
        }
        // clear loader timers
        clearInterval(window.messageInterval);
        clearTimeout(window.stuckPromptTimeout);

        // if the sidenav isn't set yet then we don't need to close it because it doesn't exist yet
        if (!this.sideNav) {
          return;
        }

        if (shouldBeOver || !appConfig.style.lockSideNav) {
          this.sideNav.close();
        }
      });
    performance.measure('appComponent.constructor', 'appComponent.constructor');

    // listen for user change events
    this.auth.currentUser$
      .pipe(
        withLatestFrom(this.config.config$),
        tap(([user, config]) => {
          // if the user it logged out then we need to hide the onboarding if it's open
          if (!user) {
            this.lazyInject
              .get<OnboardingPanelService>(() =>
                import('@ih/onboarding').then(({ OnboardingPanelService }) => OnboardingPanelService)
              )
              .then((onboarding) => {
                if (onboarding.visible) {
                  onboarding.hide();
                }
              });

            return;
          }

          // check if config.onboarding is null
          if (config.onboarding === null) {
            // if it is null then we need to set it to an empty object
            this.config.updateConfig({
              onboarding: {
                completed: false,
                hiding: false,
                createFirstPostCompleted: false,
                homeCustomizationCompleted: false,
                lookAndFeelCompleted: false,
                sharingCompleted: false
              }
            });
          }

          // if this is the owner and onboarding is not completed then open the onboarding overlay
          if (this.onboarding.shouldShowOnboarding()) {
            console.debug('Opening onboarding overlay');
            this.lazyInject
              .get<OnboardingPanelService>(() =>
                import('@ih/onboarding').then(({ OnboardingPanelService }) => OnboardingPanelService)
              )
              .then((onboarding) => {
                if (!onboarding.visible && !this.onboarding.onboarding()?.hiding) {
                  onboarding.open();
                }
              });
          }
        }),
        // if the user hasn't accepted the eula or set a password then force the complete profile dialog
        filter(([currentUser]) => currentUser && (!currentUser.acceptedEula || currentUser.password_expired)),
        tap(() => {
          this.hasShownCompleteProfile$.next();
          this.completeProfile.open();
        }),
        takeUntil(this.hasShownCompleteProfile$),
        takeUntil(this.destroy$)
      )
      .subscribe();

    this.rightSidenavService.open$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.rightSidenav.open();
    });

    this.rightSidenavService.close$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.rightSidenav.close();
    });

    if ('serviceWorker' in navigator && isPushApiSupported()) {
      this.initFcm();
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.ctrlKey && event.key === '~') {
      event.preventDefault();
      this.commandPalette.open();
    }
  }

  sideNavClick(): void {
    if (this.sideNav.mode === 'over') {
      this.sideNav.close();
    }
  }

  initFcm(): void {
    this.firebase
      .init()
      .pipe(
        tap((sw) => {
          if (!sw) {
            // something went wrong so abort
            return;
          }

          this.firebase.syncToken();
        })
      )
      .subscribe();
    this.initTokenChangeHandling();
    this.initFcmMessageHandling();
  }

  initTokenChangeHandling(): void {
    this.firebase.tokenChange$.subscribe((change) => {
      if (change.token) {
        // already allowed, ignore.
        return;
      }

      if (!change.error && !change.token) {
        // user has not been prompted to allow push yet
        if (this.auth.currentUser$.value?.notificationSettings.desktop.enabled) {
          this.snackbar
            .open('We need your permission to enable notifications', 'ENABLE', { duration: 10000 })
            .then((ref) =>
              ref.onAction().subscribe(() => {
                this.firebase.requestPermission().subscribe();
              })
            );
        }
        return;
      }

      // user has been prompted to allow push but has denied it or there was an error
      switch (change.error.code) {
        case 'messaging/permission-blocked':
        case 'messaging/notifications-blocked':
        case 'messaging/permission-default': // notification window was dismissed
          from(this.storage.get(StorageKeys.Miscellaneous)).subscribe((misc: any) => {
            if (!misc?.notificationBlockedBarClicked) {
              this.snackbar
                .open("Looks like you've blocked notifications. Please allow notifications and refresh", 'DISMISS')
                .then((ref) =>
                  ref
                    .onAction()
                    // save it to storage so won't show next time
                    .subscribe(() => {
                      this.storage.set(StorageKeys.Miscellaneous, {
                        ...(misc || {}),
                        ...{ notificationBlockedBarClicked: true }
                      });
                    })
                );
            }
          });
          break;
        case 'messaging/sw-reg-redundant':
        case 'messaging/token-subscribe-failed':
          // service worker updated, ignore.
          break;

        default:
          this.snackbar.open(
            'Something went wrong while trying to enable notifications. Please try again later',
            'DISMISS'
          );
      }
    });
  }

  initFcmMessageHandling(): void {
    // hook up to firebase messages so we can intercept push notifications when the app is focused
    // so we can show an internal message instead
    this.firebase.messages$.subscribe((payload) => {
      this.emoji
        .init()
        .then(() => {
          payload.data != null ? (payload.data['title'] ??= 'untitled') : (payload.data!.title ??= 'untitled');

          return this.snackbar.open(
            this.emoji.replaceColons(payload.data?.['title'] as string),
            payload.data?.['click_action'] ? 'VIEW' : 'OK',
            {
              duration: 10000
            }
          );
        })
        .then((ref) =>
          ref.onAction().subscribe(() => {
            if (!payload.data?.['click_action']) {
              return;
            }

            const a = document.createElement('a');
            a.href = payload.data?.['click_action'];
            // if it's builder url
            if (a.host === 'localhost:44300' || a.host.startsWith('build.') || a.host.startsWith('build-')) {
              window.open(a.href, '_blank');
            } else {
              this.router.navigateByUrl(a.pathname);
            }
          })
        );
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e): void {
    console.debug('Detected as eligible for native A2HS');
    window.deferredPrompt = e;
    window._showInstall = true;
    this.lazyInject
      .get<AddToHomeScreenService>(() =>
        import('./add-to-home-screen/add-to-home-screen.service').then((m) => m.AddToHomeScreenService)
      )
      .then((a2hs) => {
        a2hs.showInstall$.next(window._showInstall);
      });
  }
}
