import { ComponentType } from '@angular/cdk/portal';
import { Injectable, OnDestroy } from '@angular/core';
import { type MatDialogRef } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import { LazyDialogService } from '@ih/lazy-dialog';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { type SearchDialogComponent } from './search-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class SearchDialogService implements OnDestroy {
  private destroy$ = new Subject<void>();
  private dialogRef: MatDialogRef<unknown>;

  placeholder = 'Search';
  contentType = null;

  constructor(
    private lazyDialog: LazyDialogService,
    private router: Router
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  async open(): Promise<MatDialogRef<unknown>> {
    if (!this.dialogRef || this.dialogRef.getState() !== 0) {
      const searchDialogComponent: ComponentType<SearchDialogComponent> = await import(
        './search-dialog.component'
      ).then((m) => m.SearchDialogComponent);
      // listen for router events and close the dialog when the route changes
      // only run it once and remove subscription when service is destroyed
      this.router.events
        .pipe(
          filter((event) => event instanceof NavigationEnd),
          take(1),
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          this.close();
        });

      const dialog = await this.lazyDialog.getDialogService();

      this.dialogRef = dialog.open(searchDialogComponent, {
        panelClass: ['search-dialog', 'dialog-no-padding', 'dialog-fullscreen'],
        width: undefined,
        height: undefined,
        maxWidth: undefined,
        maxHeight: undefined,
        hasBackdrop: false,
        data: {
          placeholder: 'Search',
          contentType: undefined
        }
      });

      // if dialog closes then destroy the router subscription
      this.dialogRef.afterClosed().subscribe(() => {
        this.destroy$.next();
      });
    }

    return this.dialogRef;
  }

  close(): void {
    this.dialogRef.close();
  }
}
