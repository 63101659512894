import { ViewportScroller } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { APP_INITIALIZER, ApplicationRef, DoBootstrap, ErrorHandler, NgModule, NgZone } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavigationBehaviorOptions, Router, UrlTree } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CUSTOM_EVENTS, DEFAULT_MENU, ENVIRONMENT_PRODUCT, WINDOW } from '@ih/constants';
import { DeviceInfoDialogService } from '@ih/dialogs';
import { HubConnectionState, Products, StorageKeys } from '@ih/enums';
import { AppConfig } from '@ih/interfaces';
import {
  AuthService,
  ChannelService,
  ConfigService,
  ContentService,
  DebugService,
  ErrorHandlerService,
  FirebaseModule,
  FunService,
  IconRegistryService,
  IdleService,
  MonitoringService,
  ScrollService,
  SignalRService,
  StorageModule,
  StorageService
} from '@ih/services';
import { BaseUrlInterceptor } from '@ih/shared';
import { SendMessageToSw, getQueryParams, isIosDevice } from '@ih/utilities';
import { add, isBefore } from 'date-fns';
import { firstValueFrom, forkJoin, from, of } from 'rxjs';
import { map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

export function initApp(
  iconRegistry: IconRegistryService,
  debug: DebugService, // used to log debug messages needs to be here to be initialized first
  config: ConfigService<AppConfig>,
  channels: ChannelService,
  content: ContentService,
  storage: StorageService,
  auth: AuthService,
  monitoring: MonitoringService,
  signalr: SignalRService,
  deviceInfoDialog: DeviceInfoDialogService,
  idle: IdleService,
  zone: NgZone
): () => Promise<void> {
  iconRegistry.registerIcons();

  // if the app is being displayed in an iframe, disable all click and touch events
  if (window.self !== window.top) {
    document.body.style.pointerEvents = 'none';
    document.body.style.touchAction = 'none';

    // disable back navigation in iframes
    // Push a "fake" entry immediately upon loading
    history.pushState(null, null, location.href);

    window.onpopstate = function (event) {
      // When the user hits the back button, re-push the current URL
      history.pushState(null, null, location.href);
    };
  }

  content.setupListeners();

  window.initialViewportWidth = document.documentElement.clientWidth;

  const provider = async (): Promise<void> => {
    performance.mark('IH_APP_INITIALIZER');
    // check if we're running on iOS
    if (isIosDevice) {
      performance.mark('isIosDevice');
      // add maximum-scale=1.0 to the viewport meta tag if it does not already exist
      const el = document.querySelector('meta[name=viewport]');

      if (el !== null) {
        let content = el.getAttribute('content');
        const re = /maximum-scale=[0-9.]+/g;

        if (re.test(content)) {
          content = content.replace(re, 'maximum-scale=1.0');
        } else {
          content = [content, 'maximum-scale=1.0'].join(', ');
        }

        el.setAttribute('content', content);
      }
      performance.measure('isIosDevice', 'isIosDevice');
    }

    window.resetApp = (): void => {
      performance.mark('resetApp');
      zone.run(() => {
        this.snackbar.open('Clearing cache. This may take a minute...');
        this.storage.reset().then(function () {
          window.caches
            .keys()
            .then(function (keyList) {
              return Promise.all(
                keyList.map(function (key) {
                  return caches.delete(key);
                })
              );
            })
            .then(() => {
              console.log('All caches cleared');
              if (navigator.serviceWorker.controller) {
                console.log('Service worker detected. Running reset_cache.');
                return SendMessageToSw({ action: 'reset_cache' });
              }

              return 'complete';
            })
            .catch(function (err) {
              console.error(err);
              this.snackbar.open('Reset failed. Error: ' + err);
            });
        });
      });
      performance.measure('resetApp');
    };

    window.deviceInfo = (): void => {
      zone.run(() => deviceInfoDialog.open());
    };

    config.registerConfigTransformer((appConfig: AppConfig) => {
      return of(appConfig).pipe(
        switchMap(() => from(storage.get(StorageKeys.CacheVersion))),
        switchMap((version) => {
          if (version && version !== appConfig.cacheVersion + appConfig.cacheVersionSuffix) {
            console.debug(
              '[Content] AppStart - Flushing local cache. Current version [' +
                version +
                '] does not match [' +
                appConfig.cacheVersion +
                appConfig.cacheVersionSuffix +
                ']'
            );

            // cache version mismatch, flush the cache
            return from(
              storage
                .set(StorageKeys.CacheVersion, appConfig.cacheVersion + appConfig.cacheVersionSuffix)
                .then(() => content.reset())
            ).pipe(map(() => appConfig));
          }

          if (!version) {
            console.debug(
              '[Content] AppStart - No cache version found. Setting to [' +
                appConfig.cacheVersion +
                appConfig.cacheVersionSuffix +
                ']'
            );
            // no cache version found, set it
            return from(
              storage
                .set(StorageKeys.CacheVersion, appConfig.cacheVersion + appConfig.cacheVersionSuffix)
                .then(() => content.reset())
            ).pipe(map(() => appConfig));
          }

          return of(appConfig);
        }),
        tap((appConfig) => {
          // check for hostname to support legacy config structure
          if (appConfig.hostName) {
            // check hostname for migration
            const canonicalHostName = appConfig.hostName.startsWith('https://')
              ? new URL(appConfig.hostName).hostname
              : appConfig.hostName;
            if (canonicalHostName !== window.location.hostname && window.location.hostname !== 'localhost') {
              const currentUrl = new URL(window.location.href);
              currentUrl.hostname = canonicalHostName;
              window.location.href = currentUrl.href;
              console.log('[Content] AppStart - Redirecting to new hostname [' + canonicalHostName + ']');
            }
          }

          performance.mark('config.transform');
          appConfig.menu = appConfig.menuJson ? JSON.parse(appConfig.menuJson) : DEFAULT_MENU;

          performance.measure('config.transform', 'config.transform');
        })
      );
    });

    signalr.stateChanged$.subscribe((state) => {
      if (state === HubConnectionState.Connected) {
        // sync the config with the server
        forkJoin([config.syncConfig(), config.syncTheme()]).subscribe();
        channels.channels$.subscribe();
      }
    });

    config.config$.pipe(withLatestFrom(signalr.stateChanged$)).subscribe(([appConfig, signalrState]) => {
      performance.mark('config.configChanged');
      // if this is a public app or the user is logged in, connect immediately
      const search = getQueryParams(window.location.search);
      if (
        (!appConfig.isPrivate || appConfig.userLoggedIn) &&
        signalrState === HubConnectionState.Disconnected &&
        search.prerender !== '1'
      ) {
        signalr.connect();
      }

      window.campaign = appConfig;
      window.cdnUrl = appConfig.cdnUrl;

      // remove existing icon links from the head
      const links = document.querySelectorAll('link[rel="icon"], link[rel="apple-touch-icon"]');
      for (let i = 0; i < links.length; i++) {
        links[i].remove();
      }

      const frag = document.createDocumentFragment();
      appConfig.style.icons.forEach((icon) => {
        const link = document.createElement('link');
        link.setAttribute('rel', icon.platformId === 2 ? 'apple-touch-icon' : 'icon');
        link.setAttribute('sizes', `${icon.width}x${icon.height}`);
        link.setAttribute('href', icon.cdnUrl);
        frag.append(link);
      });
      window.document.head.append(frag);

      // delay matomo and AI tracking until the app is ready

      setTimeout(() => {
        performance.mark('monitoring.init');
        if (appConfig.enableMatomo) {
          if (!document.getElementById('matomoTracking')) {
            // matomo init
            const _paq = (window._paq = window._paq || []);
            (function () {
              const u = '//matomo.ihub.app/';
              _paq.push(['setTrackerUrl', u + 'matomo.php']);
              _paq.push(['setSiteId', window.campaign.piwikId]);
              _paq.push(['enableLinkTracking']);
              const d = document;
              const g = d.createElement('script');
              const s = d.getElementsByTagName('script')[0];
              g.id = 'matomoTracking';
              g.type = 'text/javascript';
              g.async = true;
              g.src = u + 'matomo.js';
              s.parentNode.insertBefore(g, s);
            })();
          }
        }

        monitoring.init(appConfig.keys.aiKey, appConfig.environment.version).subscribe();
        performance.measure('monitoring.init', 'monitoring.init');
      }, 10000);

      performance.measure('config.configChanged', 'config.configChanged');
    });

    // load the config before the app starts
    // use cache 'default' so it will use cached value (faster), since the default is to skip cache
    const appConfig = await firstValueFrom(config.syncConfig({ cache: 'default' }));
    console.log('Config loaded');

    idle.requestIdle(
      () => {
        // make sure the cached theme is up to date
        config.syncTheme().subscribe();
      },
      5,
      5000
    );

    await storage.rehydrate(appConfig);
    await auth.init();

    // Listen for storage resets and rehydrate the storage
    storage.reset$
      .pipe(
        withLatestFrom(config.config$),
        tap(([_, appConfig]) => {
          // Rehydrate storage with the config
          storage.rehydrate(appConfig);
        })
      )
      .subscribe();

    // if lastContentSync is set then we need to run catchup
    if (storage.lastContentSync) {
      // if the date is > 30 days ago then skip catchup and do a flush
      if (isBefore(storage.lastContentSync, add(new Date(), { days: -30 }))) {
        console.debug(
          `[Content] DoOfflineCatchup - Flushing local cache. Cache is too stale [${storage.lastContentSync}]`
        );

        // cache is too stale, flush it
        content.reset();
      }
      // run catchup pbm asynchrously
      content.doOfflineCatchup(storage.lastContentSync);
    } else {
      console.debug('[Content] DoOfflineCatchup - Never synced before. Skipping');
    }

    storage.scanExpiredContent();

    document.dispatchEvent(new CustomEvent(CUSTOM_EVENTS.APP_START_COMPLETE));

    performance.measure('IH_APP_INITIALIZER', 'IH_APP_INITIALIZER');
  };

  return provider;
}

@NgModule({
  imports: [
    BrowserAnimationsModule,
    BrowserModule,

    MatNativeDateModule,

    AppRoutingModule,

    ServiceWorkerModule.register('/sw.js', { enabled: true }),
    StorageModule.forRoot({
      idbName: 'ihubApp',
      idbStoreName: 'data',
      idbSchemaVersion: 2
    }),
    FirebaseModule.forRoot({
      apiKey: 'AIzaSyCHhrP8cbemEhpGMKE2L8Uigl6PDVde4oE',
      authDomain: 'quantum-gearbox-557.firebaseapp.com',
      databaseURL: 'https://quantum-gearbox-557.firebaseio.com',
      projectId: 'quantum-gearbox-557',
      storageBucket: 'quantum-gearbox-557.appspot.com',
      messagingSenderId: '261205718171',
      appId: '1:261205718171:web:3a57afd49efd33e2e8423e',
      vapid: 'BI5DtqI3h8Db7NQGHawXFQ1TEpKN8GPizj_QN0U2b_WzhNY39ctC4L_Q4z1u6k6sC709W2hYcDZpt5j1UnB37-g',
      apiUrl: '/api/account/pushNotification/fcm',
      measurementId: 'G-3EGFRZW3WQ'
    }),

    AppComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [
        IconRegistryService,
        DebugService,
        ConfigService,
        ChannelService,
        ContentService,
        StorageService,
        AuthService,
        MonitoringService,
        SignalRService,
        DeviceInfoDialogService,
        IdleService,
        NgZone
      ]
    },
    { provide: WINDOW, useValue: window },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: ENVIRONMENT_PRODUCT, useValue: Products.App },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
      multi: true
    },
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())
  ]
})
export class AppModule implements DoBootstrap {
  private lastUrl: string;

  constructor(
    _auth: AuthService,
    _config: ConfigService<AppConfig>,
    _signalr: SignalRService,
    _router: Router,
    _viewportScroller: ViewportScroller,
    _scroll: ScrollService,
    _fun: FunService
  ) {
    performance.mark('AppModule.constructor');

    _fun.init();

    history.scrollRestoration = 'manual';

    document.version = window.version;
    console.info('HUB - Running version: ' + window.version);

    window.navigateByUrl = (url: string | UrlTree, extras?: NavigationBehaviorOptions): Promise<boolean> => {
      return _router.navigateByUrl(url, extras);
    };

    _scroll.listenToScrollEvents();

    if ('serviceWorker' in navigator) {
      if (!navigator.serviceWorker.controller) {
        // if this window is not being controlled (service worker is not loaded yet)
        // and this is the first load, we need to prime the cache once service worker is loaded
        navigator.serviceWorker.ready.then(() => {
          // wait for SW cache to become ready so we can make sure these are cached
          setTimeout(() => {
            fetch('/api/config');
            fetch('/api/config/theme');
          });
        });
      }
    }

    _auth.logout$.subscribe(() => {
      _config.syncConfig().subscribe();
    });

    _signalr.forceUpdateCheck$.subscribe(() => {
      forkJoin([_config.syncConfig(), _config.syncTheme()]).subscribe();
    });
    performance.measure('AppModule.constructor', 'AppModule.constructor');
  }

  ngDoBootstrap(appRef: ApplicationRef): void {
    appRef.bootstrap(AppComponent);
  }
}
