@if (isOpen()) {
  <div class="command-palette" [class.scrolled]="scrolled()">
    <div class="command-palette-header">
      <input
        #inputElement
        [formControl]="query"
        placeholder="Type a command..."
        (keypress.enter)="executeSelected()"
        autocomplete="off"
        type="search"
      />
    </div>
    <div #commandList class="command-list-container" (scroll)="onScroll($event)">
      <mat-selection-list
        #selectionList
        class="command-list-select"
        [multiple]="false"
        [hideSingleSelectionIndicator]="true"
        [formControl]="selectedCommand"
        [compareWith]="compareFn"
      >
        @for (command of filteredCommands(); track command.name; let i = $index) {
          <mat-list-option [value]="command">
            <div class="command-list-option" (click)="execute(command.name)">
              {{ command.name }}
            </div>
          </mat-list-option>
        }
        @if (filteredCommands().length === 0) {
          <option class="no-results">No results found</option>
        }
      </mat-selection-list>
    </div>
  </div>
}
