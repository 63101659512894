import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppConfig, Channel, SideNav, UpNav } from '@ih/interfaces';
import { ConfigService, StorageService } from '@ih/services';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, pairwise, startWith, take, takeUntil, withLatestFrom } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UpNavService implements OnDestroy {
  private destroy$ = new Subject<void>();

  upNav$ = new BehaviorSubject<UpNav>({} as UpNav);

  private typeAliasSingular = {
    posts: 'Post',
    events: 'Event'
  };

  private retiredTypes = ['albums', 'news', 'stories', 'getInvolved'];

  constructor(
    private config: ConfigService<AppConfig>,
    private storage: StorageService,
    private router: Router
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        startWith(null),
        pairwise<NavigationEnd>(),
        withLatestFrom(this.storage.appStorage$, this.config.config$),
        takeUntil(this.destroy$)
      )
      // nested destructuring assignments baby, YEAH!
      .subscribe(([[oldRouteEvent, newRouteEvent], appStorage, appConfig]) => {
        const sideNav = appConfig.menu.sideNav;

        const homeUpNav: UpNav = {
          title: this.getTitle(sideNav, '/home', 'Home')
        };

        const oldUrl = oldRouteEvent?.urlAfterRedirects;
        // on first load there is only 1 route event
        const newUrl = (newRouteEvent || oldRouteEvent).urlAfterRedirects;
        // menu items are treated as top level and don't get an up arrow
        const rootMatch =
          /^\/(home|channels|account|admin|messages$|give|contact|posts[^/]*$|albums[^/]*$|events[^/]*$|polls[^/]*$|search)/gi.exec(
            newUrl
          );
        if (rootMatch) {
          // clear up nav
          return this.clear();
        }

        const oldParsedUrl = oldUrl && new URL(oldUrl, window.location.origin);
        const newParsedUrl = newUrl && new URL(newUrl, window.location.origin);

        // ignore events that are on the same path
        if (oldParsedUrl?.pathname === newParsedUrl?.pathname) {
          return;
        }

        const pathChanged = newUrl !== oldUrl;
        // if firstLoad is undefined this is the first load
        // if this is not the first load then things are handled differently
        if (oldUrl) {
          // check if we're coming from a channel
          const channelFeedMatch = /\/c\/([^/]+)\/feed/gi.exec(oldUrl);
          if (channelFeedMatch && pathChanged) {
            // if we're coming from a channel then the up arrow should be to that channel
            const channelId = channelFeedMatch[1];
            const channel = (Object.values(appStorage.channels) as Channel[]).find(
              (channel) => channel.channelId.toString() === channelId || channel.handle === channelId
            );
            if (channel) {
              this.setNav({
                title: channel.name
              });
            }
          }

          // check if we're coming from home
          const homefeedMatch = /^\/home/gi.exec(oldUrl);
          if (homefeedMatch) {
            // if we're coming from home then the up arrow should be to home
            return this.setNav(homeUpNav);
          }

          // if we're coming from search then the up arrow should be to search
          const searchFeedMatch = /^\/search\?q=[^&]+/gi.exec(oldUrl);
          if (searchFeedMatch) {
            // if we're coming from search then the up arrow should be to search
            return this.setNav({
              title: this.getTitle(sideNav, '/search', 'Search')
            });
          }

          // if we came from the channels list then go back there
          const channelMatch = /^\/channels\?(categoryId=[0-9]*&)?opt=([^&]+)/gi.exec(oldUrl);
          if (channelMatch) {
            return this.setNav({
              title: this.getTitle(sideNav, '/channels', 'Channels')
            });
          }

          // if we're on the channel feed then go back to the channels list
          const channelMatchNew = /^\/c\/([^/]+)\/feed/gi.exec(newUrl);
          if (channelMatchNew) {
            const channelOldMatch = /^\/channels(:?\?.?opt=.+)?/gi.exec(oldUrl);
            if (channelOldMatch) {
              return this.setNav({
                title: this.getTitle(sideNav, '/channels', 'Channels')
              });
            } else return this.clear();
          }

          // message detail up arrow goes to message list
          const messageDetailMatch = /^\/messages\/([^/]+)|compose/gi.exec(newUrl);
          if (messageDetailMatch) {
            return this.setNav({
              title: this.getTitle(sideNav, '/messages', 'Messages')
            });
          }

          const typeDetailMatch = /^\/(posts|events)\/([^/]+)(?:\/[^/]+)?/gi.exec(newUrl);
          if (typeDetailMatch) {
            // check if we're coming from a channel
            const channelFeedMatch = /\/c\/([^/]+)\/feed/gi.exec(oldUrl);
            if (channelFeedMatch && pathChanged) {
              // if we're coming from a channel then the up arrow should be to that channel
              const channelId = channelFeedMatch[1];
              const channel = (Object.values(appStorage.channels) as Channel[]).find(
                (channel) => channel.channelId.toString() === channelId || channel.handle === channelId
              );
              if (channel) {
                return this.setNav({
                  title: channel.name
                });
              }
            }

            const contentType = typeDetailMatch[1];
            if (this.retiredTypes.indexOf(contentType) !== -1) {
              return this.setNav(homeUpNav);
            }
            if (this.typeAliasSingular[contentType]) {
              return this.setNav({
                title: this.getTitle(sideNav, `/${contentType}`, this.typeAliasSingular[contentType], true)
              });
            }

            return this.setNav(homeUpNav);
          }
        }

        return this.clear();
      });
  }

  private getTitle(sideNav: SideNav, url: string, fallback: string, singular = false): string {
    const match = Object.values(sideNav).find((item) => item.url === url);

    return (singular ? match?.singularTitle : null) || match?.title || fallback;
  }

  setNav(opts: UpNav): void {
    this.upNav$.pipe(take(1)).subscribe((upNav) => {
      this.upNav$.next({ ...upNav, ...opts });
    });
  }

  clear(): void {
    this.upNav$.next({} as UpNav);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
