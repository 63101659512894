import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // if the user is absolute url, do nothing
    if (req.url.startsWith('http')) {
      return next.handle(req);
    }

    // get the origin url from the location
    const origin = window.location.origin;
    // trim leading slash from request url
    const url = req.url.startsWith('/') ? req.url.substring(1) : req.url;

    const apiReq = req.clone({ url: `${origin}/${url}` });
    return next.handle(apiReq);
  }
}
