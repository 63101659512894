import { Component, ElementRef, HostListener, ViewChild, inject, signal } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatListModule, MatSelectionList } from '@angular/material/list';
import * as fuzzysort from 'fuzzysort';
import { debounceTime, map, of, startWith, switchMap } from 'rxjs';
import { Command, CommandService } from '../command.service';

@Component({
  selector: 'ih-command-palette',
  standalone: true,
  imports: [ReactiveFormsModule, MatListModule],
  templateUrl: './command-palette.component.html',
  styleUrl: './command-palette.component.scss'
})
export class CommandPaletteComponent {
  private commandService = inject(CommandService);
  private el = inject(ElementRef);
  isOpen = signal(false);

  scrolled = signal(false);

  selectedCommand = new FormControl<Command[]>([]);
  filteredCommands = signal<Command[]>([]);

  query = new FormControl('');

  @ViewChild('inputElement') inputElement!: ElementRef<HTMLInputElement>;

  @ViewChild('selectionList') selectionList!: MatSelectionList;

  constructor() {
    this.commandService.registerCommandPalette(this);

    this.query.valueChanges
      .pipe(
        debounceTime(100),
        startWith(''),
        map((query) => query?.trim() ?? ''),
        switchMap((query) => {
          let allCommands = this.commandService.getCommands();
          if (!query) {
            const recentCommandNames = this.commandService.getRecentCommands();
            let recentCommands: Command[] = [];

            if (recentCommandNames.length > 0) {
              // convert the recent command names to the actual command objects
              recentCommands = recentCommandNames
                .map((name) => allCommands.find((cmd) => cmd.name === name))
                .filter((cmd): cmd is Command => !!cmd); // Filter out undefined values
            }

            // remove recent commands from all commands and sort
            allCommands = allCommands
              .filter((cmd) => !recentCommands.some((recentCmd) => recentCmd.name === cmd.name))
              .sort((a, b) => a.name.localeCompare(b.name));
            // concat recent commands to the front
            allCommands = recentCommands.concat(allCommands);

            return of(allCommands);
          }
          const results = fuzzysort.go(query, allCommands, {
            keys: ['name'],
            threshold: -10000
          });

          return of(results.map((result) => result.obj));
        })
      )
      .subscribe((commands) => {
        this.filteredCommands.set(commands);
        this.selectedCommand.setValue((commands?.length ?? 0) > 0 ? [commands[0]] : []);
      });
  }

  @HostListener('window:click', ['$event'])
  handleClick(event: MouseEvent) {
    if (!this.isOpen()) {
      return;
    }

    if (!this.el.nativeElement.contains(event.target)) {
      this.close();
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (!this.isOpen()) return;

    switch (event.key) {
      case 'ArrowDown':
        this.selectNext();
        event.preventDefault();
        break;
      case 'ArrowUp':
        this.selectPrevious();
        event.preventDefault();
        break;
      case 'Enter':
        this.executeSelected();
        event.preventDefault();
        break;
      case 'Escape':
        this.close();
        event.preventDefault();
        break;
    }
  }

  onScroll(event: Event) {
    // if the element is not scrollTop = 0, we are scrolled
    this.scrolled.set((event.target as HTMLElement).scrollTop !== 0);
  }

  toggle(): void {
    this.isOpen.update((isOpen) => !isOpen);
    if (this.isOpen()) {
      this.focusInput();
    }
  }

  open(): void {
    this.isOpen.set(true);
    this.focusInput();
  }

  close(): void {
    this.isOpen.set(false);
    this.query.reset();
  }

  execute(name: string) {
    this.commandService.execute(name);
    this.close();
  }

  executeSelected() {
    const selectedCommand = (this.selectedCommand.value?.length ?? 0) > 0 ? this.selectedCommand.value![0] : undefined;
    if (selectedCommand) {
      this.execute(selectedCommand.name);
    }
  }

  selectNext() {
    const selectedOption = this.selectionList.selectedOptions.selected[0].value as Command;
    // get the index of the currently selected option
    const selectedIndex = this.filteredCommands().findIndex((cmd) => cmd.name === selectedOption.name);

    this.selectedCommand.setValue([this.filteredCommands()[(selectedIndex + 1) % this.filteredCommands().length]]);

    this.scrollSelectedItemIntoView();
  }

  selectPrevious() {
    const selectedOption = this.selectionList.selectedOptions.selected[0].value as Command;
    // get the index of the currently selected option
    const selectedIndex = this.filteredCommands().findIndex((cmd) => cmd.name === selectedOption.name);

    this.selectedCommand.setValue([
      this.filteredCommands()[(selectedIndex - 1 + this.filteredCommands().length) % this.filteredCommands().length]
    ]);

    this.scrollSelectedItemIntoView();
  }

  private scrollSelectedItemIntoView() {
    const selectedOptions = this.selectionList.selectedOptions.selected;
    if (selectedOptions.length > 0) {
      const lastSelectedOption = selectedOptions[selectedOptions.length - 1]._elementRef.nativeElement;
      lastSelectedOption.scrollIntoView({ block: 'nearest' });
    }
  }

  private focusInput() {
    setTimeout(() => this.inputElement.nativeElement.focus(), 0);
  }

  compareFn = (o1: Command, o2: Command) => o1.name === o2.name;
}
