import { NgModule, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, UrlMatchResult, UrlSegment } from '@angular/router';
import { POST_DETAIL_ROUTE_PREFIXES } from '@ih/constants';
import { SaveChangesDialogService } from '@ih/dialogs';
import { BaseClientConfig } from '@ih/interfaces';
import { ConfigService, PrivateAppGuardService } from '@ih/services';
import { map, of } from 'rxjs';

@NgModule({
  providers: [],
  imports: [
    RouterModule.forRoot(
      [
        { path: '', redirectTo: '/home', pathMatch: 'full' },
        {
          path: 'home',
          loadComponent: () => import('./channel-feed/channel-feed.component').then((m) => m.ChannelFeedComponent),
          resolve: {
            channelId: () => {
              const config = inject(ConfigService) as ConfigService<BaseClientConfig>;
              return config.config$.pipe(map((config) => config.homeChannel.channelId));
            },
            showOverlay: () => of(false),
            showHeader: () => of(false)
          },
          canActivate: [PrivateAppGuardService, SaveChangesDialogService],
          canDeactivate: [SaveChangesDialogService]
        },
        {
          path: 'login',
          redirectTo: '/account/login'
        },
        {
          path: 'register',
          redirectTo: '/account/login'
        },
        {
          path: 'account',
          loadChildren: () => import('./account/account.module').then((m) => m.AccountModule)
        },
        {
          matcher: (url: UrlSegment[]): UrlMatchResult => {
            if (url.length === 1 && Object.keys(POST_DETAIL_ROUTE_PREFIXES).includes(url[0].path)) {
              // if the url is length 1 and matches one of the prefixes then this is a request for a content list page
              // consume the route and use the content list component
              return {
                consumed: url,
                posParams: {
                  contentType: url[0]
                }
              };
            }
          },
          loadChildren: () =>
            import('./content-list-page/content-list-page.component').then((m) => m.ContentListPageComponent),
          data: { contentType: 'posts', contentTypeAlias: 'Posts', defaultIcon: 'newspaper' },
          canActivate: [PrivateAppGuardService, SaveChangesDialogService],
          canDeactivate: [SaveChangesDialogService]
        },
        {
          matcher: (url: UrlSegment[]) => {
            if (url.length > 1 && Object.keys(POST_DETAIL_ROUTE_PREFIXES).includes(url[0].path)) {
              return {
                consumed: []
              };
            }
          },
          loadChildren: () => import('./posts/posts.module').then((m) => m.PostsModule),
          canActivate: [PrivateAppGuardService, SaveChangesDialogService],
          canDeactivate: [SaveChangesDialogService]
        },
        {
          path: 'events',
          loadChildren: () => import('./events/events.module').then((m) => m.EventsModule),
          canActivate: [PrivateAppGuardService, SaveChangesDialogService],
          canDeactivate: [SaveChangesDialogService]
        },
        {
          path: 'upgrade',
          loadChildren: () => import('@ih/upgrade').then((m) => m.UpgradeModule),
          canActivate: [PrivateAppGuardService]
        },
        {
          path: 'channels',
          loadComponent: () => import('@ih/channel').then((m) => m.ChannelListComponent),
          canActivate: [PrivateAppGuardService, SaveChangesDialogService],
          canDeactivate: [SaveChangesDialogService]
        },
        {
          path: 'messages',
          loadChildren: () => import('@ih/messaging').then((m) => m.MessagesModule),
          canActivate: [PrivateAppGuardService, SaveChangesDialogService],
          canDeactivate: [SaveChangesDialogService]
        },
        {
          path: 'tags',
          loadChildren: () => import('./tags/tags.module').then((m) => m.TagsModule),
          canActivate: [PrivateAppGuardService, SaveChangesDialogService],
          canDeactivate: [SaveChangesDialogService]
        },
        {
          path: 'admin',
          loadChildren: () => import('@ih/admin').then((m) => m.AdminModule),
          canActivate: [PrivateAppGuardService, SaveChangesDialogService],
          canDeactivate: [SaveChangesDialogService]
        },
        {
          path: 'a/:authorId',
          loadComponent: () => import('./user-profile/user-profile.component').then((m) => m.AuthorFeedComponent),
          canActivate: [PrivateAppGuardService, SaveChangesDialogService],
          canDeactivate: [SaveChangesDialogService]
        },
        {
          path: 'a/:authorId/:handle',
          loadComponent: () => import('./user-profile/user-profile.component').then((m) => m.AuthorFeedComponent),
          canActivate: [PrivateAppGuardService, SaveChangesDialogService],
          canDeactivate: [SaveChangesDialogService]
        },
        {
          path: 'c',
          children: [
            {
              path: '',
              redirectTo: '/channels',
              pathMatch: 'full'
            },
            {
              path: ':channelId',
              redirectTo: ':channelId/feed'
            },
            {
              path: ':channelId/feed',
              loadComponent: () => import('./channel-feed/channel-feed.component').then((m) => m.ChannelFeedComponent),
              resolve: {
                channelId: (route: ActivatedRouteSnapshot) => {
                  return route.params.channelId;
                },
                showOverlay: () => of(true),
                showHeader: () => of(true)
              }
            }
          ],
          canActivate: [PrivateAppGuardService, SaveChangesDialogService],
          canDeactivate: [SaveChangesDialogService]
        },
        {
          path: 'contact',
          loadComponent: () => import('./contact/contact.component').then((m) => m.ContactComponent),
          canActivate: [PrivateAppGuardService, SaveChangesDialogService],
          canDeactivate: [SaveChangesDialogService]
        },
        {
          path: 'give',
          loadComponent: () => import('./give/give.component').then((m) => m.GiveComponent),
          canActivate: [PrivateAppGuardService, SaveChangesDialogService],
          canDeactivate: [SaveChangesDialogService]
        },
        { path: '**', redirectTo: '/home' }
      ],
      {
        anchorScrolling: 'enabled',
        scrollOffset: [0, 72],
        scrollPositionRestoration: 'disabled'
        // enableTracing: true
      }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
