import { enableProdMode } from '@angular/core';
import '@angular/localize/init';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import 'zone.js';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

window.addEventListener('beforeinstallprompt', (e) => {
  console.debug('Detected as eligible for native A2HS');
  window.deferredPrompt = e;
  window._showInstall = true;
});

const providers = [{ provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }];

if (environment.production) {
  enableProdMode();
}

const bootstrap = () => platformBrowserDynamic(providers).bootstrapModule(AppModule);

bootstrap().catch((err) => console.log(err));
