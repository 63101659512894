<ng-container
  *ngIf="{
    currentUser: currentUser$ | async,
    updateAvailable: updateAvailable$ | async,
    config: config$ | async,
    shouldBeOver: shouldBeOver$ | async,
    connectionState: connectionState$ | async,
    logoSrc: logoSrc$ | async
  } as data"
>
  <mat-toolbar id="topNavHeaderWrapper">
    <mat-toolbar-row id="topNavHeader">
      <ng-container *ngIf="upNav$ | async as upNav">
        <button
          mat-icon-button
          @inOutAnimation
          id="menu-toggle"
          class="accent-background-color-hover accent-background-color-focus top-nav-menu-btn"
          type="button"
          (click)="toggleSidenav.emit()"
          trackClick="Top Nav Menu button clicked"
          *ngIf="!upNav.title && data.shouldBeOver && (!data.config.isPrivate || data.currentUser)"
          aria-label="menu"
        >
          <mat-icon class="primary-font-color accent-font-color-hover accent-font-color-focus">
            <svg viewBox="0 0 24 24">
              <path fill="currentColor" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
            </svg>
          </mat-icon>
        </button>
        <button
          mat-icon-button
          @inOutAnimation
          class="up-nav accent-background-color-hover accent-background-color-focus top-nav-up-btn"
          [ngClass]="{ 'has-up-icon': upNav.icon }"
          *ngIf="upNav.title"
          (click)="back()"
          trackClick="Top Nav Up button clicked"
          [trackProperties]="{ title: upNav.title }"
          aria-label="navigate up"
        >
          <mat-icon svgIcon="arrow-left" class="primary-font-color accent-font-color-hover" aria-label="back icon">
          </mat-icon>
        </button>
      </ng-container>
      <span class="fx-flex"></span>
      <a id="logo-link" class="brand" routerLink="/home" (click)="homeClicked()" trackClick="Top Nav App logo clicked">
        <img #logoImg class="logo logo-main-nav" [src]="data.logoSrc" alt="logo" />
      </a>
      <button
        mat-icon-button
        class="accent-background-color-hover accent-background-color-focus top-nav-show-search-btn"
        (click)="showSearch()"
        trackClick="Top Nav Show search icon button clicked"
        *ngIf="!data.updateAvailable"
        aria-label="search"
      >
        <mat-icon class="primary-font-color accent-font-color-hover" aria-label="search icon">
          <svg viewBox="0 0 24 24">
            <path
              d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
            />
          </svg>
        </mat-icon>
      </button>
      <button
        mat-icon-button
        class="update-button accent-background-color-hover accent-background-color-focus"
        (click)="updateNow()"
        trackClick="Top Nav Update now icon button clicked"
        *ngIf="data.updateAvailable && !data.config.isTemplate"
        aria-label="update app"
      >
        <mat-icon svgIcon="update" class="primary-font-color accent-font-color-hover" aria-label="update icon">
        </mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
</ng-container>
