<ih-command-palette #commandPalette></ih-command-palette>
<mat-sidenav-container>
  <mat-sidenav
    #sideNav
    id="drawer"
    *ngIf="{
      config: config$ | async,
      isAuthenticated: isAuthenticated$ | async,
      shouldBeOver: shouldBeOver$ | async
    } as opts"
    [fixedTopGap]="!opts.shouldBeOver && opts.config.style.lockSideNav ? 56 : 0"
    [opened]="
      (!opts.config.isPrivate || opts.isAuthenticated) && !opts.shouldBeOver && opts.config.style.lockSideNav
        ? true
        : null
    "
    position="start"
    [fixedInViewport]="true"
    [mode]="!opts.shouldBeOver && opts.config.style.lockSideNav ? 'side' : 'over'"
    [disableClose]="!opts.shouldBeOver && opts.config.style.lockSideNav"
    (click)="sideNavClick()"
  >
    <ng-container *ngIf="!opts.config.isPrivate || opts.isAuthenticated">
      <ng-container *ngIf="leftDrawerComponent$ | async as leftDrawerComponent; else loadingSideNav">
        <ng-container *ngComponentOutlet="leftDrawerComponent"></ng-container>
      </ng-container>
    </ng-container>
  </mat-sidenav>

  <mat-sidenav-content id="main-content" class="body-content">
    <header>
      <ih-top-nav (toggleSidenav)="sideNav.toggle()"></ih-top-nav>
    </header>
    <main>
      @if (loadingRoute()) {
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      }
      <router-outlet></router-outlet>
    </main>
  </mat-sidenav-content>
  <mat-sidenav #rightSidenav position="end" mode="over" [fixedInViewport]="true">
    <ng-container *ngComponentOutlet="rightDrawerComponent$ | async"></ng-container>
  </mat-sidenav>
</mat-sidenav-container>
<ng-template #devConsole></ng-template>

<ng-template #loadingSideNav>
  <div class="mat-sidenav-scaffold">
    <div class="mat-sidenav-customize-scaffold">
      <div class="mat-sidenav-customize-button pulse"></div>
    </div>
    <div class="mat-sidenav-item-scaffold">
      <div class="mat-sidenav-item-icon-scaffold pulse"></div>
      <div class="mat-sidenav-item-text-scaffold pulse"></div>
    </div>
    <div class="mat-sidenav-item-scaffold">
      <div class="mat-sidenav-item-icon-scaffold pulse"></div>
      <div class="mat-sidenav-item-text-scaffold pulse"></div>
    </div>
    <div class="mat-sidenav-item-scaffold">
      <div class="mat-sidenav-item-icon-scaffold pulse"></div>
      <div class="mat-sidenav-item-text-scaffold pulse"></div>
    </div>
    <div class="mat-sidenav-item-scaffold">
      <div class="mat-sidenav-item-icon-scaffold pulse"></div>
      <div class="mat-sidenav-item-text-scaffold pulse"></div>
    </div>
    <div class="mat-sidenav-item-scaffold">
      <div class="mat-sidenav-item-icon-scaffold pulse"></div>
      <div class="mat-sidenav-item-text-scaffold pulse"></div>
    </div>
    <div class="mat-sidenav-item-scaffold">
      <div class="mat-sidenav-item-icon-scaffold pulse"></div>
      <div class="mat-sidenav-item-text-scaffold pulse"></div>
    </div>
    <div class="mat-sidenav-item-scaffold">
      <div class="mat-sidenav-item-icon-scaffold pulse"></div>
      <div class="mat-sidenav-item-text-scaffold pulse"></div>
    </div>
    <div class="mat-sidenav-item-scaffold">
      <div class="mat-sidenav-item-icon-scaffold pulse"></div>
      <div class="mat-sidenav-item-text-scaffold pulse"></div>
    </div>
    <span class="version-spacer-scaffold"></span>
    <div class="version-container-scaffold">
      <div class="version-scaffold pulse"></div>
    </div>
  </div>
</ng-template>
