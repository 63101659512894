import { animate, style, transition, trigger } from '@angular/animations';
import { AsyncPipe, NgClass, NgComponentOutlet, NgIf, ViewportScroller } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  OnDestroy,
  Output,
  inject
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { type MatSnackBarRef } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { CompleteProfileDialogService } from '@ih/complete-profile-dialog';
import { DEFAULT_LOGO_RENDER_OPTIONS } from '@ih/constants';
import { TrackClickDirective } from '@ih/directives';
import { AppConfig, UpNav } from '@ih/interfaces';
import {
  AuthService,
  CheckForUpdateService,
  ConfigService,
  FirebaseService,
  LayoutService,
  LazySnackBarService,
  SignalRService,
  StorageService
} from '@ih/services';
import { type SnackBarComponent } from '@ih/snackbar';
import { getImgUrl, isPushApiSupported } from '@ih/utilities';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SearchDialogService } from '../search-dialog/search-dialog.service';
import { UpNavService } from '../services/up-nav.service';

@Component({
  selector: 'ih-top-nav',
  templateUrl: './top-nav.component.html',
  styleUrls: ['./top-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    NgClass,
    NgComponentOutlet,
    NgIf,
    RouterLink,

    MatButtonModule,
    MatIconModule,
    MatToolbarModule,

    TrackClickDirective
  ],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [style({ opacity: 0 }), animate('.3s ease-out', style({ opacity: 1 }))]),
      transition(':leave', [style({ opacity: 1 }), animate('.3s ease-in', style({ opacity: 0 }))])
    ])
  ]
})
export class TopNavComponent implements OnDestroy {
  @HostBinding('class.ih-top-nav') hostClass = true;
  @Output() toggleSidenav = new EventEmitter<void>();

  private auth = inject(AuthService);
  private http = inject(HttpClient);
  private route = inject(ActivatedRoute);
  private router = inject(Router);
  private cd = inject(ChangeDetectorRef);
  private signalr = inject(SignalRService);
  private firebase = inject(FirebaseService);
  private upNav = inject(UpNavService);
  private snackbar = inject(LazySnackBarService);
  private config = inject(ConfigService<AppConfig>);
  private completeProfile = inject(CompleteProfileDialogService);
  private checkForUpdate = inject(CheckForUpdateService);
  private layout = inject(LayoutService);
  private search = inject(SearchDialogService);
  private viewportScroller = inject(ViewportScroller);
  private storage = inject(StorageService);

  pushError: string;
  resendingVerification = false;
  hideUpdate = false;
  isPushAPISupported = isPushApiSupported();
  fcmInitialized = false;
  pushAllowed = null;
  reloading = false;
  resendBusy = false;
  emailResent = false;
  hideUnverified = false;
  hidePush = false;
  signalRShow = false;
  hideDevBar = false;

  currentUser$ = this.auth.currentUser$;
  showLetsGoBanner$ = this.currentUser$.pipe(
    map((user) => {
      this.layout.setCompleteProfile(false);

      if (!user) {
        return false;
      }

      if (!user.profileComplete) {
        this.layout.setCompleteProfile(true);
      }

      return !user.profileComplete;
    })
  );

  upNav$: Observable<UpNav>;
  logoSrc$ = this.config.config$.pipe(
    map((config: AppConfig) => {
      // legacy config check
      if (!config.style.logoImage) {
        return getImgUrl(config.style.logoUrls[0].url, null, DEFAULT_LOGO_RENDER_OPTIONS);
      }

      return getImgUrl(config.style.logoImage.url, config.style.logoImage.cropData, DEFAULT_LOGO_RENDER_OPTIONS);
    })
  );

  config$ = this.config.config$;
  updateAvailable$: Observable<boolean>;
  connectionState$: Observable<string>;
  shouldBeOver$: Observable<boolean>;

  private readonly destroy$ = new Subject<void>();
  private lastScrollTop = 0;
  private upAmount = 0;
  private downAmount = 0;
  private readonly DELTA = 5;
  private readonly HEADER_HEIGHT = 56;
  private readonly UP_THRESHOLD = 56;
  private readonly DOWN_THRESHOLD = 256;

  private offline;

  constructor() {
    // whether the sideNav should be over or push based on the width of the browser
    this.shouldBeOver$ = this.layout.shouldBeOver$;

    this.upNav$ = this.upNav.upNav$;

    this.signalr.offline$.pipe(takeUntil(this.destroy$)).subscribe((offline) => {
      setTimeout(() => {
        this.offline = offline;
      });
    });

    // check if serviceworker is supported
    if ('serviceWorker' in navigator) {
      // listen for serviceworker updates so we can show the update banner
      this.updateAvailable$ = this.checkForUpdate.updateAvailable$.pipe(map(() => true));
    } else {
      console.log("Service workers aren't supported in this browser.");
    }

    // delay showing connection message for 10 seconds so it's only visible if it's taking a long time to connect on first load
    setTimeout(function () {
      this.signalRShow = true;
    }, 10000);

    // get auth setup
    const currentUser = this.auth.currentUser$.getValue();
    // if we're not logged in and the login param is set then we need to check for profile info ASAP
    if (this.route.snapshot.paramMap.get('login')) {
      // remove login param
      this.router.navigate([], {
        queryParams: { login: null },
        queryParamsHandling: 'merge',
        replaceUrl: true,
        relativeTo: this.route
      });

      if (!currentUser) {
        this.auth.syncUser();
      }
    }

    // detect page reloading so we can suppress disconnection notices
    window.onpagehide = (): void => {
      if (window.performance && performance.navigation.type === 1) {
        this.reloading = true;
        this.cd.markForCheck();
      }
    };

    // if you start the app with a dialog already set then reset the state
    if (this.route.snapshot.paramMap.get('dialog')) {
      this.router.navigate([], {
        queryParams: { dialog: null },
        queryParamsHandling: 'merge',
        replaceUrl: true,
        relativeTo: this.route
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  homeClicked(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  showCompleteProfile(): void {
    this.completeProfile.open();
  }

  resendEmail(): void {
    this.resendBusy = true;
    this.http.post('/api/account/verify', {}).subscribe(() => {
      this.resendBusy = false;
      this.emailResent = true;
    });
  }

  showSearch(): MatSnackBarRef<SnackBarComponent> {
    if (this.offline) {
      // show a toast to tell the user they must be online to search
      this.snackbar.open('You must be online to search');
      return;
    }

    this.search.open();
  }

  updateNow(): void {
    this.checkForUpdate.updateNow();
  }

  back(): void {
    window.history.back();
  }
}
