export const environment = {
  production: true,
  hmr: false,
  firebase: {
    apiKey: 'AIzaSyCHhrP8cbemEhpGMKE2L8Uigl6PDVde4oE',
    authDomain: 'quantum-gearbox-557.firebaseapp.com',
    databaseURL: 'https://quantum-gearbox-557.firebaseio.com',
    projectId: 'quantum-gearbox-557',
    storageBucket: 'quantum-gearbox-557.appspot.com',
    messagingSenderId: '261205718171',
    appId: '1:261205718171:web:3a57afd49efd33e2e8423e'
  }
};
